// @import '~flag-icon-css/css/flag-icon.min.css';
// @import '~font-awesome/css/font-awesome.min.css';
// @import '~simple-line-icons/css/simple-line-icons.css';

@import "~@fullcalendar/core/main.css";
@import "~@fullcalendar/daygrid/main.css";
@import "~react-bootstrap-toggle/dist/bootstrap2-toggle.css";
@import "~react-sliding-pane/dist/react-sliding-pane.css";

.lookup_btn .img {
  width: auto !important;
}

.assetsearch {
  padding-top: 10px;
}

.asset-search-block {
  cursor: pointer;
}

.tab-content {
  .react-datepicker-wrapper {
    display: block;
  }
}

.expenses_btn_block {
  padding-top: 5px;
}

.radio-btn__radio-custom {
  border: 1px solid black;
}

.radio-btn__radio:checked + .radio-btn__radio-custom {
  border-color: rgb(24, 67, 84);
  background: #29333d;
}

.contact_update img,
.btns_remove img {
  width: auto;
}

.password-toggle-btn.active {
  color: #13a7ff;
}

.password-toggle-btn {
  position: absolute;
  top: 36px;
  right: 36px;
  color: #29333d;
}

.forgot-password {
  float: right;
  margin: 10px 0;
}

@media (max-width: 500px) {
  .account-wrapper {
    max-width: 380px !important;
  }
}

.account-wrapper {
  max-width: 500px;
  margin: auto;
  padding: 10px;

  .card-title {
    > img {
      width: 40%;
    }
  }

  .account-or {
    position: relative;
  }

  .account-or::before,
  .account-or::after {
    content: "";
    height: 1px;
    width: calc(50% - 90px);
    background: #dddddd;
    position: absolute;
    top: 10px;
  }

  .account-or::after {
    right: 0;
  }

  .account-or::before {
    left: 0;
  }
}

.navbar-brand {
  > img {
    width: 60%;
  }
  @media (max-width: 768px) {
    > img {
      width: 100%;
    }
  }
}

.worksheet_content {
  .due-dates {
    margin-top: -4rem;
  }
}

.rc-slider {
  margin-top: 10px;
}

.btn.dropdown-toggle.w-100 {
  white-space: normal !important;
}

@media (max-width: 520px) {
  .topnav .navbar-nav {
    flex-direction: column;
  }
}

.carousel_card {
  .dashboard__carousel {
    .slick-dots {
      bottom: -35px;
    }

    .slick-arrow.slick-prev,
    .slick-arrow.slick-next {
      background: none;
      opacity: 0;
      pointer-events: none;
    }

    .dashboard__carousel-slide {
      border-radius: 5px;
      padding: 15px 13px;
      position: relative;
      overflow: hidden;

      svg {
        position: absolute;
        height: 80px;
        width: 80px;
        top: calc(50% - 40px);
        opacity: 0.3;
        right: 0;
        //
        // @include themify($themes) {
        //   fill: themed('colorIcon');
        // }
      }

      p {
        // color: $color-additional;
        margin: 0;
      }

      .dashboard__carousel-title {
        // color: $color-red;
        font-size: 24px;
        font-weight: 500;
      }

      // @include themify($themes) {
      //   background: themed('colorBackgroundBody');
      // }

      &.dashboard__carousel-slide--red {
        // background: $color-red;

        p {
          color: white;
          opacity: 0.45;
        }

        svg {
          fill: #ffffff;
        }

        .dashboard__carousel-title {
          opacity: 1;
        }
      }
    }
  }
}

.asset-lookup {
  margin-top: 100px;
}

@media (max-width: 700px) {
  .btn-group .react-datepicker-wrapper {
    display: none !important;
  }
  .btn-group .date-bettween-text {
    display: none !important;
  }
}
.rc-switch {
  .rc-switch-inner {
    margin-left: 20px !important;
  }
}

.rc-switch-checked {
  .rc-switch-inner {
    margin-left: -20px !important;
  }
}

.deal-summary {
  .slick-slide {
    height: auto; // ← that must not be ignored
  }
  .slider-item {
    height: auto;
  }
  .slick-track {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: stretch;
  }
}

.media-slider {
  .slider-item {
    height: auto;
    background: none;
    margin: 20px 0;
  }
}

.view-attachment {
  &.loading-container {
    align-items: center !important;
    display: flex;
    flex-direction: row;
    height: 100%;
    justify-content: center;
    width: 100%;
  }
}

@media (max-width: 768px) {
  html,
  body {
    width: auto !important;
    overflow-x: hidden !important;
  }
}

.react-datepicker-wrapper-with-time {
  .react-datepicker__month-container {
    margin-right: 140px;
  }
  .react-datepicker__navigation--next--with-time:not(.react-datepicker-wrapper-with-time--with-today-button) {
    right: 150px;
  }
  .react-datepicker__time-container {
    margin: 1rem;
    border: 0;
    position: absolute;
    top: 0;
    right: 0;

    .react-datepicker__time {
      border: #e6e7f1 solid 1px;

      .react-datepicker__time-box {
        margin-bottom: -2px;
      }
    }
  }
}

.primaryBg {
  background: #13a7ff;
}

.secondaryBg {
  background: #61697a;
}

.bgColorRed {
  background: #ff184b;
}

.bgColorPurple {
  background: #9553f6;
}

.bgColorGreen {
  background: #4ae19e;
}

html {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
}

body {
  font-family: "Poppins", sans-serif;
  background-color: #f2f2f2;
  font-size: 1.14rem;
  font-weight: 300;
  color: #29333d;
  line-height: 1.8;
  position: relative;
}

img {
  max-width: 100%;
  display: inline-block;
}

button,
input,
textarea,
select {
  font-family: "Poppins", sans-serif;
  opacity: 1;
  letter-spacing: 0.0143rem;
}

button:focus,
input:focus,
textarea:focus,
select:focus {
  outline: none;
}

ul,
li {
  padding: 0px;
  margin: 0px;
  list-style: none;
}

a {
  display: inline-block;
  -webkit-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
  color: #0057ff;
}

a:hover,
a:focus {
  outline: inherit;
  text-decoration: none;
}

a:hover {
  color: #2e56bf;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Poppins", sans-serif;
  padding: 0px 0px;
  margin: 0px 0px;
}

section::after {
  display: block;
  clear: both;
  content: "";
}

input::-webkit-input-placeholder {
  color: #000000;
  opacity: 1;
}

input::-moz-placeholder {
  color: #000000;
  opacity: 1;
}

input:-ms-input-placeholder {
  color: #000000;
  opacity: 1;
}

input::-ms-input-placeholder {
  color: #000000;
  opacity: 1;
}

input::-webkit-input-placeholder {
  color: #000000;
  opacity: 1;
}

input:-ms-input-placeholder {
  color: #000000;
  opacity: 1;
}

input::placeholder {
  color: #000000;
  opacity: 1;
}

header {
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 1px 6px 0px rgba(57, 73, 76, 0.35);
}

.card_box {
  border-radius: 3px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 1px 6px 0px rgba(57, 73, 76, 0.35);
  color: #29333d;
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 16px;
  border-top: 3px solid #13a7ff;
}

.card_box_secondary {
  border-top: 3px solid #61697a;
}

.card_box_purple {
  border-top: 3px solid #9553f6;
}

.card_box h3 {
  font-size: 19px;
  font-weight: 400;
  color: #29333d;
  text-transform: uppercase;
}

.card_box h2 {
  font-size: 20px;
  font-weight: 500;
  color: #13a7ff !important;
  padding: 4px 0;
}

.card_box_purple h2 {
  color: #9553f6 !important;
}

.card_box_secondary h2 {
  color: #61697a !important;
}

.card_box .profile_graph {
  font-size: 13px;
  font-weight: 400;
  color: #a4abc5;
}

.card_box .profile_graph svg {
  width: 24px;
  margin-right: 5px;
}

.card_box .profile_graph span.profit {
  color: #0fcaad;
}

.card_box .profile_graph span.loss {
  color: #eb116d;
}

.section_title {
  border-bottom: 1px solid #d3d3d3;
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}

.section_title h4 {
  color: #184354;
  font-size: 24px;
  font-weight: 400;
  position: relative;
  padding-top: 20px;
}

.section_title h4:after {
  width: 100%;
  height: 2px;
  background-color: rgb(19, 167, 255);
  content: "";
  bottom: -1px;
  left: 0;
  position: absolute;
}

a.add_btns {
  background-color: rgb(19, 167, 255);
  box-shadow: 0px 4px 14px 0px rgba(19, 167, 255, 0.45);
  width: 185px;
  height: 41px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  border: 1px solid rgb(19, 167, 255);
  font-weight: 500;
  font-size: 14px;
}

a.add_btns svg.add {
  width: 14px;
  fill: #fff;
  margin-right: 6px;
}

a.add_btns:hover {
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 1px 43px 0px rgba(57, 73, 76, 0.2);
  width: 185px;
  height: 41px;
  color: rgb(19, 167, 255);
  border: 1px solid rgb(19, 167, 255);
}

a.add_btns:hover svg.add {
  fill: rgb(19, 167, 255);
}

a.add_btns.secondary {
  background: #61697a;
}
a.add_btns.secondary:hover {
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 1px 43px 0px rgba(57, 73, 76, 0.2);
}

.search_block {
  background-color: #ffffff;
  border-radius: 3px;
}

.search_assets .form-group {
  margin-bottom: 0px;
  padding-bottom: 0;
  position: relative;
}

.search_assets .form-group a {
  position: absolute;
  top: 0;
  left: 0;
  padding: 6px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 45px;
}

.search_assets .form-group a svg {
  width: 15px;
  fill: rgb(19, 167, 255);
}

.search_assets .form-group .form-control {
  box-shadow: none;
  border: none;
  height: 40px;
  color: #656565;
  font-weight: 400;
  padding-left: 40px;
}

.shortby {
  display: flex;
  float: right;
  height: 40px;
  align-items: center;
}

.shortby span {
  font-size: 15px;
  color: #656565;
  font-weight: 400;
  display: inline-block;
  padding-right: 8px;
}

.shortby .dropdown a.dropdown-toggle {
  background-color: transparent;
  box-shadow: none;
  border: none;
  color: #13a7ff;
  font-size: 15px;
  font-weight: 400;
}

.shortby .dropdown a.dropdown-toggle:hover,
.shortby .dropdown a.dropdown-toggle:focus,
.shortby .dropdown a.dropdown-toggle:visited,
.shortby .dropdown a.dropdown-toggle:active {
  border: none;
  box-shadow: none;
  border-radius: 0;
  background-color: transparent;
}

.select-custom {
  padding: 0;
  margin: 0;
  min-width: 30px;
  border-radius: 3px;
  overflow: hidden;
  background-color: #fff;
  background: #fff url("img/downarrow.svg") no-repeat 95% 50%;
  background-size: 11px;
  font-weight: 300;
}

.select-custom select {
  padding: 5px 30px 5px 5px;
  width: 100%;
  border: none;
  box-shadow: none;
  background-color: transparent;
  background-image: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  color: #13a7ff;
}

.select-custom select:focus {
  outline: none;
}

.assets_block {
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 1px 6px 0px rgba(57, 73, 76, 0.35);
  margin-bottom: 30px;
  border-top: 3px solid #fff;
  -webkit-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}

.assets_block:hover {
  box-shadow: 0px 1px 32px 0px rgba(57, 73, 76, 0.35);
  border-top: 3px solid #13a7ff;
  cursor: pointer;
}

.assets_info {
  padding: 12px 15px 8px 15px;
  display: flex;
}

.assets_info .assets_img {
  width: 93px;
  height: 93px;
  border-radius: 5px;
}

.assets_info .assets_img img {
  width: 93px;
  height: 93px;
  max-width: 93px;
  max-height: 93px;
  border-radius: 5px;
}

.assets_name {
  padding: 0 0 0 15px;
}

.assets_name h4 {
  font-size: 21px;
  color: #184354;
  font-weight: 500;
}

.assets_name p {
  font-size: 15px;
  color: #13a7ff;
  font-weight: 400;
}

.assets_name p svg.location {
  width: 20px;
  fill: rgb(19, 167, 255);
}

.assets_card_info {
  background-color: #f9f9f9;
}

.assets_card_info table th {
  font-size: 14px;
  color: #222222;
  font-weight: 600;
}

.assets_card_info table td,
.assets_card_info table th {
  padding: 0.45rem 0.75rem;
}

.assets_card_info table th {
  font-size: 14px;
  color: #222222;
  font-weight: 600;
}

.assets_card_info table td {
  font-size: 14px;
  color: #373737;
  font-weight: 400;
}

.assets_card_info table td span.profit {
  display: inline-block;
  padding: 0 6px;
}

.assets_card_info table td span.profit svg {
  width: 12px;
  fill: rgb(19, 167, 255);
}

.assets_card_info table td span.loss {
  display: inline-block;
  padding: 0 6px;
}

.assets_card_info table td span.loss svg {
  width: 12px;
  fill: rgb(235, 17, 109);
}

.loadmore a {
  background-color: rgb(24, 67, 84);
  box-shadow: 0px 6px 17px 0px rgba(57, 73, 76, 0.35);
  min-width: 216px;
  max-width: 216px;
  border-radius: 30px;
  min-height: 48px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.loadmore a svg {
  width: 20px;
  fill: rgb(255, 255, 255);
  margin-right: 15px;
}

.loadmore a:hover svg {
  fill: rgb(24, 67, 84);
}

.loadmore a:hover {
  border-style: solid;
  border-width: 1px;
  border-color: rgb(24, 67, 84);
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 6px 17px 0px rgba(57, 73, 76, 0.35);
  color: rgb(24, 67, 84);
}

footer {
  background-color: #fff;
  padding: 25px 0;
  font-size: 14px;
  color: #3b3e66 !important;
  font-weight: 400;
}

footer .footernav span {
  color: #3b3e66;
  font-weight: 400;
  font-size: 16px;
  display: inline-block;
  padding: 0 20px;
}

footer .footernav a {
  color: #3b3e66;
  font-weight: 400;
  font-size: 16px;
}

footer .footernav a:hover {
  color: #fff;
}

.socialmedia {
  text-align: right;
}

.socialmedia a {
  display: inline-block;
  background-repeat: no-repeat;
  width: 33px;
  height: 33px;
}

.socialmedia a.blog {
  background-image: url(img/socialmedia.png);
  background-position: -8px 0px;
}

.socialmedia a.blog:hover {
  background-image: url(img/socialmedia.png);
  background-position: -8px -87px;
}

.socialmedia a.instagram {
  background-image: url(img/socialmedia.png);
  background-position: -55px 0px;
}

.socialmedia a.instagram:hover {
  background-position: -55px -87px;
}

.socialmedia a.twitter {
  background-image: url(img/socialmedia.png);
  background-position: -102px 0px;
}

.socialmedia a.twitter:hover {
  background-position: -102px -87px;
}

.socialmedia a.facebook {
  background-image: url(img/socialmedia.png);
  background-position: -149px 0px;
}

.socialmedia a.facebook:hover {
  background-position: -149px -87px;
}

.logo {
  padding: 10px 0;
  margin-right: 35px;
}

.notification {
  background-image: url(img/notification.png);
  background-repeat: no-repeat;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  height: 65px;
  display: inline-block;
  width: 59px;
  background-position: 50% 22px;
  position: relative;
}

.notificatin_alert {
  border-radius: 50%;
  background-color: rgb(235, 17, 109);
  box-shadow: 0px 4px 15px 0px rgba(238, 25, 115, 0.55);
  position: absolute;
  width: 12px;
  height: 12px;
  top: 19px;
  right: 14px;
}

.search {
  background-image: url(img/notification.png);
  background-repeat: no-repeat;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  height: 65px;
  display: inline-block;
  width: 65px;
  background-position: 50% 22px;
}

.searchbox {
  border-left: 1px solid #ddd;
  padding: 8px 15px;
}

.searchbox input {
  outline: none;
}

.searchbox input[type="search"] {
  -webkit-appearance: textfield;
  -webkit-box-sizing: content-box;
  font-family: inherit;
  font-size: 100%;
}

.searchbox input::-webkit-search-decoration,
.searchbox input::-webkit-search-cancel-button {
  display: none;
}

.searchbox input[type="search"] {
  background: url(img/search.png) no-repeat 5px center;
  background-color: transparent;
  border: none;
  padding: 9px 10px 9px 32px;
  width: 65px;
  font-weight: 400;
  -webkit-border-radius: 10em;
  -moz-border-radius: 10em;
  border-radius: 10em;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  transition: all 0.5s;
}

.searchbox input[type="search"]:focus {
  width: 150px;
  background-color: #fff;
  border-color: #66cc75;
  -webkit-box-shadow: 0 0 5px rgba(109, 207, 246, 0.5);
  -moz-box-shadow: 0 0 5px rgba(109, 207, 246, 0.5);
  box-shadow: 0 0 5px rgba(109, 207, 246, 0.5);
}

.searchbox input:-moz-placeholder {
  color: #999;
}

.searchbox input::-webkit-input-placeholder {
  color: #999;
}

.adminuser {
  min-width: 210px;
  padding-top: 13px;
}

.adminuser .username {
  color: #535252;
  font-weight: 400;
  font-size: 15px;
}

a[aria-expanded="true"] {
  background-color: transparent;
}

.adminuser > .btn-secondary.dropdown-toggle {
  background-color: transparent;
  box-shadow: none;
  border: none;
}

.adminuser button {
  background-color: transparent;
  box-shadow: none;
  border: none;
  border-radius: 0;
  color: #535252;
  padding: 0px;
  position: relative;
}

.adminuser button[aria-expanded="true"] {
  background-color: transparent;
  box-shadow: none;
}

.adminuser .btn-secondary:not(:disabled):not(.disabled):active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  background-color: transparent;
  box-shadow: none;
}

.adminuser button:hover,
.adminuser button:focus,
.adminuser button:visited {
  background-color: transparent;
  box-shadow: none;
  border: none;
  border-radius: 0;
  color: #535252;
}

.adminuser button:after {
  position: absolute;
  right: 5px;
  top: 6px;
  color: #13a7ff;
  border-top: 0.4em solid;
  border-right: 0.4em solid transparent;
  border-bottom: 0;
  border-left: 0.4em solid transparent;
}

.adminuser .dropdown-menu {
  border-bottom: none;
  padding-bottom: 0px;
  padding-top: 0;
}

.adminuser .dropdown-menu .dropdown-item {
  border-bottom: 1px solid #ddd;
  padding-top: 6px;
  padding-bottom: 6px;
}

.adminuser .dropdown-menu .dropdown-item:hover {
  background-color: #13a7ff;
  color: #fff;
  font-weight: 400;
}

.userimg {
  width: 34px;
  height: 34px;
  border-radius: 50%;
  border: 1px solid #c6ccd8;
  margin: 0 14px;
}

.userimg img {
  width: 34px;
  height: 34px;
  border-radius: 50%;
}

.available {
  color: #9f9f9f;
  font-size: 13px;
  font-weight: 400;
  text-align: left;
}

.available span {
  border-radius: 50%;
  background-color: rgb(15, 202, 173);
  width: 9px;
  height: 9px;
  display: inline-block;
}

.adminuser .dropdown-menu {
  border-radius: 0px;
  min-width: 220px;
}

.topnav .navbar-nav .nav-item {
  margin-right: 30px;
}

.topnav .navbar-nav .nav-link {
  border-bottom: 2px solid #fff;
  padding: 20px 12px 10px 0px;
  background-repeat: no-repeat;
  color: #757575;
  font-size: 17px;
  font-weight: 400;
  text-transform: uppercase;
  transition: none;
}
.topnav .navbar-nav .nav-link i {
  padding-right: 5px;
}

.topnav .dashboard a {
  background-image: url(img/icon_dashboard.png);
  background-position: 0 27px;
}

.topnav .resources a {
  background-image: url(img/icon_resources.png);
  background-position: 0 27px;
}

.topnav .navbar-nav .active .nav-link,
.topnav .navbar-nav .nav-link:hover {
  border-bottom: 2px solid #13a7ff;
  color: #29333d;
  background-position: 0 -76px;
}

.navbar-toggler {
  background-color: #13a7ff;
  cursor: pointer;
}

.toggler-icon svg {
  fill: rgb(255, 255, 255);
  position: relative;
  top: -3px;
  left: -2px;
}

.input-group-lg {
  .react-datepicker-wrapper {
    width: 69%;
  }

  .form-control:not([type="search"]) {
    padding: 5px !important;
  }

  > .input-group-prepend > .input-group-text {
    padding: 15px !important;
  }
}

/*---Add Proparty---*/

.addproparty-item {
  display: block;
}

.add-proparty {
  border-width: 1px;
  border-color: rgb(183, 183, 183);
  border-style: dashed;
  background-color: rgb(242, 242, 242);
  box-shadow: 0px 1px 6px 0px rgba(57, 73, 76, 0.35);
  min-height: 188px;
  display: flex;
  justify-content: space-between;
  transition: all 0.5s ease-out;
}

.addproparty-item .add-proparty:hover {
  box-shadow: 0px 1px 32px 0px rgba(57, 73, 76, 0.35);
}

.addproparty-details {
  padding: 15px;
  display: flex;
  align-items: center;
}

.add-proparty-cion {
  border-width: 1px;
  border-color: rgb(183, 183, 183);
  border-style: dashed;
  background-color: rgb(226, 226, 226);
  padding: 30px;
  width: 122px;
  float: left;
}

.add-proparty-message {
  width: calc(100% - 122px);
  float: left;
  padding-left: 15px;
  padding-right: 15px;
}

.add-proparty-message h4 {
  color: #ff0083;
  font-size: 18px;
  font-weight: 500;
}

.add-proparty-message p {
  color: #353535;
  font-size: 15px;
  font-weight: 400;
  line-height: 22px;
}

.addproparty-plus {
  border-left: 1px dashed rgb(183, 183, 183);
  background-color: rgb(249, 249, 249);
  padding: 0 25px;
  display: flex;
  align-items: center;
}

/*-----Asset lookup-----*/

.asset-lookup {
  border-radius: 5px;
  background-color: rgb(255, 255, 255);
  border-top: 3px solid #13a7ff;
}

.asset-title {
  background-color: #f9f9f9;
  padding: 15px;
}

.asset-title h4 {
  color: #184354;
  font-size: 22px;
  font-weight: 500;
  text-transform: uppercase;
}

.asset-title p {
  color: #656565;
  font-size: 14px;
  font-weight: 400;
}

.assetsearch .form-group {
  position: relative;
}

.assetsearch .form-group input.form-control {
  height: 45px;
  border-style: solid;
  border-width: 1px;
  border-color: rgb(195, 198, 203);
  border-radius: 3px;
  background-color: rgb(255, 255, 255);
  padding-right: 155px;
  padding-left: 35px;
  background-image: url(img/location.png);
  background-repeat: no-repeat;
  background-position: 10px 50%;
}

.assetsearch .lookasset_btn {
  background-color: #13a7ff;
  height: 45px;

  background-size: 20px;
  fill: #fff;
  background-repeat: no-repeat;
  background-position: 10px 50%;
  padding: 0 10px 0 10px;
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  color: #fff;
  border-radius: 0 4px 4px 0px;
}

.asset-searchlist {
  padding: 15px;
}

.asset-searchlist h6 {
  font-size: 15px;
  color: #9d9d9d;
  font-weight: 300;
  padding-bottom: 15px;
}

.asset-search-block {
  display: flex;
  border-style: solid;
  border-width: 1px;
  border-color: rgb(195, 198, 203);
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 1px 6px 0px rgba(57, 73, 76, 0.07);
  justify-content: space-between;
  margin-bottom: 20px;
  transition: all 0.5s ease-out;
}

.asset-search-block:hover {
  border-style: solid;
  border-width: 1px;
  border-color: rgb(95, 187, 255);
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 4px 16px 0px rgba(57, 73, 76, 0.15);
}

.asset-search-deatils {
  display: flex;
  align-items: center;
  padding: 10px;
}

.asset-listthumb {
  width: 104px;
  max-height: 77px;
  border-style: solid;
  border-width: 1px;
  border-color: rgb(195, 198, 203);
  background-color: rgb(255, 255, 255);
  margin-right: 15px;
  overflow: hidden;
}

.asset-search-deatils h4 {
  font-size: 17px;
  color: #184354;
  font-weight: 500;
}

.asset-search-deatils p {
  font-size: 15px;
  color: #656565;
  font-weight: 300;
  margin: 0;
}

a.nextbtns {
  background-color: #fafafa;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

a.nextbtns svg {
  width: 12px;
  fill: #13a7ff;
}

/*-----Left Sidebar------*/

.left_sidebar {
  border-radius: 3px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 0px 7.76px 0.24px rgba(0, 0, 50, 0.15);
}

.profile_thumb {
  max-height: 182px;
  overflow: hidden;
}

.property_address {
  background-color: #f8f8f8;
}

.property_address p {
  padding: 0px 15px 15px 15px;
  font-size: 16px;
  color: #373737;
  font-weight: 400;
}

.property_address p span {
  font-size: 14px;
  color: #373737;
  display: block;
}

/*-----left sidebar menu-------*/

.property_nav > .dropdown-toggle,
.property_nav > .dropdown-toggle:focus {
  font-size: 20px;
  color: #184354;
  text-align: left;
  box-shadow: none;
  border: none;
  font-weight: 500;
  position: relative;
}

.property_nav > .dropdown-toggle:after {
  position: absolute;
  right: 15px;
  top: 40%;
}

.property_nav > .dropdown-menu {
  right: 15px !important;
  left: inherit !important;
  border-style: solid;
  border-width: 1px;
  border-color: #dfdfdf;
  border-radius: 3px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 0px 7.76px 0.24px rgba(0, 0, 50, 0.15);
  padding: 0px;
}

.property_nav > .dropdown-menu[x-placement^="bottom"],
.property_nav > .dropdown-menu[x-placement^="left"],
.property_nav > .dropdown-menu[x-placement^="right"],
.property_nav > .dropdown-menu[x-placement^="top"] {
  left: auto;
}

.property_nav > .dropdown-menu a {
  border-bottom: 1px solid #dfdfdf;
  color: #373737;
  font-size: 15px;
  padding: 6px 0 6px 15px;
  background-repeat: no-repeat;
  background-position: 5px 12px;
}

.icon_export {
  background-image: url(img/icon_export.png);
}

.icon_share {
  background-image: url(img/icon_share.png);
}

.icon_email {
  background-image: url(img/icon_email.png);
}

.icon_delete {
  background-image: url(img/icon_delete.png);
}

.property_nav > .dropdown-menu a:hover {
  color: #13a7ff;
  background-position: 5px -51px;
}

.property_nav > .dropdown-menu a:last-child {
  border-bottom: none;
  color: #eb116d;
  background-position: 5px -51px;
}

.dashboard_nav {
  font-size: 17px;
  color: #184354;
}

.dashboard_nav ul {
  margin: 0px;
  padding: 0px 0;
  list-style: none;
}

.dashboard_nav li {
  padding: 4px 0;
}

.dashboard_nav li a {
  font-size: 16px;
  color: #184354;
  border-right: 4px solid #fff;
  padding: 10px 15px 10px 65px;
  font-weight: 500;
  display: flex;
  background-repeat: no-repeat;
  background-position: 26px 11px;
  transition: none;
}

.dashboard_nav li a svg {
  width: 21px;
  fill: #000;
  margin-right: 10px;
  position: relative;
  top: -2px;
}

.icon_overview {
  background-image: url(img/icon_overview.png);
}

.icon_financials {
  background-image: url(img/icon_financials.png);
}

.icon_transactions {
  background-image: url(img/icon_transactions.png);
}

.icon_worksheet {
  background-image: url(img/icon_worksheet.png);
}

.icon_attachments {
  background-image: url(img/icon_attachments.png);
}

.icon_contacts {
  background-image: url(img/icon_contacts.png);
}

.icon_map {
  background-image: url(img/icon_map.png);
  background-position: 26px 11px;
}

.icon_notifications {
  background-image: url(img/icon_notifications.png);
}

.dashboard_nav li a:hover {
  color: #184354;
  border-right: 4px solid #13a7ff;
  background-color: #f2f2f2;
  background-position: 26px -46px;
}

.dashboard_nav li.active a {
  color: #13a7ff;
  border-right: 4px solid #13a7ff;
  background-color: #f2f2f2;
  background-position: 26px -46px;
}

/*-----left sidebar menu END-------*/

.inner_pagetitle {
  border-bottom: 1px solid #d3d3d3;
  font-size: 24px;
  color: #184354;
  display: flex;
  justify-content: space-between;
}

.inner_pagetitle h4 {
  font-size: 24px;
  color: #184354;
  position: relative;
  padding-bottom: 10px;
  font-weight: 400;
  bottom: -8px;
}

.inner_pagetitle h4:after {
  border-bottom: 3px solid #13a7ff;
  width: 100%;
  position: absolute;
  content: "";
  left: 0;
  bottom: 8px;
}

.inner_pagetitle div.cta_btns {
  display: flex;
}

@media (max-width: 576px) {
  .inner_pagetitle div.cta_btns {
    display: inline-block;
  }
}

.asset_delete_btns {
  border-style: solid;
  border-width: 1px;
  border-color: rgb(235, 17, 109);
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 4px 14px 0px rgba(24, 67, 84, 0.22);
  min-height: 39px;
  display: inline-block;
  border-radius: 29px;
  color: #184354;
  padding: 6px 20px 6px 40px;
  font-size: 14px;
  background-image: url(img/icon_delete.png);
  background-repeat: no-repeat;
  background-position: 12px 9px;
  font-weight: 400;
}

.asset_delete_btns:hover {
  background-position: 12px -53px;
  color: rgb(235, 17, 109);
}

.worksheet_content {
  border-radius: 3px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 0px 7.76px 0.24px rgba(0, 0, 50, 0.15);
}

.inputblock label {
  font-size: 14px;
  color: #6f6f6f;
  font-weight: 400;
}

.inputblock .input_price {
  border-style: solid;
  border-width: 1px;
  border-color: rgb(211, 211, 211);
  border-radius: 3px;
  background-color: rgb(252, 252, 252);
  height: 47px;
}

.has_symbol .input_price {
  padding-left: 40px;
}
.usd_icon {
  position: absolute;
  background-color: #fff;
  height: 44px;
  display: inline-block;
  width: 31px;
  left: 26px;
  bottom: 1px;
  border-right: 1px solid rgb(211, 211, 211);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px 0px 0 3px;
  padding: 0 6px;
  font-size: 14px;
  font-weight: 500;
  color: #373737;
}

.percentage_icon {
  position: absolute;
  background-color: #fff;
  height: 45px;
  display: inline-block;
  min-width: 31px;
  right: 16px;
  bottom: 1px;
  border-left: 1px solid rgb(211, 211, 211);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0px 3px 3px 0px;
  padding: 0 6px;
  font-size: 14px;
  font-weight: 500;
  color: #373737;
}

.p-price-left {
  padding-left: 40px;
}

.p-percentage-right {
  padding-right: 40px;
}

.btn-primary {
  background-color: rgb(19, 167, 255);
  box-shadow: 0px 4px 14px 0px rgba(19, 167, 255, 0.45);
  height: 41px;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  border-radius: 30px;
  border: 1px solid rgb(19, 167, 255);
}

.btn-primary:hover,
.btn-primary:focus {
  background-color: rgb(255, 255, 255);
  color: rgb(19, 167, 255);
}

/*----------expenses-block-------*/

.expenses-block {
  border-style: solid;
  border-width: 1px;
  border-color: rgb(223, 223, 223);
  background-color: rgb(252, 252, 252);
  padding: 10px;
  margin-bottom: 20px;
}

.expenses-block .inputblock {
  color: #184354;
  font-size: 16px;
  font-weight: 500;
}

.expenses-block .inputfilds {
  width: calc(100% - 180px);
  float: left;
}

.section_title .btntoggal {
  margin-bottom: 15px;
  margin-right: 10px;
}
.section_title .date-bettween-text {
  margin-top: 10px;
  padding: 0px 10px;
}

.inputfilds-block .btntoggal,
.inputfilds-block .btntoggal:focus,
.inputfilds-block .btntoggal:hover {
  background-color: #fff;
  border: 1px solid #ddd;
  box-shadow: none;
}

.expenses_btn_block {
  width: 180px;
  float: left;
}

.expenses_block_sub {
  display: flex;
  padding: 0 0px 0 10px;
  justify-content: space-around;
}

.expenses_block_sub a {
  border-style: solid;
  border-width: 1px;
  border-color: rgb(173, 173, 173);
  border-radius: 50%;
  background-color: rgb(255, 255, 255);
  width: 41px;
  height: 41px;
  background-position: 50% 10px;
  background-repeat: no-repeat;
}

a.btn_cal {
  background-image: url(img/icon_cal.png);
}

a.btn_edit {
  background-image: url(img/icon_edit.png);
}

a.btn_del {
  background-image: url(img/icon_del.png);
}

.expenses_block_sub a:hover {
  background-color: #13a7ff;
  border-color: #13a7ff;
  background-position: 50% -49px;
}

.newexpensemodal h5.modal-title {
  font-size: 20px;
  color: #184354;
  font-weight: 500;
}

.btn-secondary {
  border-style: solid;
  border-width: 1px;
  border-color: rgb(195, 198, 203);
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 1px 32px 0px rgba(57, 73, 76, 0.17);
  height: 39px;
  border-radius: 30px;
  color: #184354;
  font-weight: 500;
  font-size: 15px;
}

.btn-secondary:hover {
  color: #eb116d;
  border-color: #eb116d;
}

.savebtns btn-primary {
  border-style: solid;
  border-width: 1px;
  border-color: rgb(19, 167, 255);
  background-color: rgb(19, 167, 255);
  box-shadow: 0px 4px 14px 0px rgba(19, 167, 255, 0.45);
  height: 39px;
  border-radius: 30px;
  color: #fff;
  font-weight: 500;
  font-size: 15px;
}

.savebtns btn-primary:hover {
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 1px 32px 0px rgba(57, 73, 76, 0.17);
  color: #184354;
  border-color: rgb(195, 198, 203);
}

.bordered_table {
  border-radius: 3px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 0px 7.76px 0.24px rgba(0, 0, 50, 0.15);
  border-top: 3px solid #13a7ff;
}

.contacttable thead th {
  background-color: #f2f2f2;
  font-size: 14px;
  color: #184354;
  font-weight: 500;
}

.contacttable tbody td {
  font-size: 14px;
  color: #484848;
  font-weight: 400;
}

.contact_update {
  color: #13a7ff;
  font-size: 14px;
}

.contact_update:hover {
  color: #13a7ff;
}

.btns_remove {
  color: #eb116d;
  font-size: 14px;
}

.btns_remove:hover {
  color: #eb116d;
}

.select-addcontact {
  padding: 0;
  margin: 0;
  width: 100%;
  border-radius: 3px;
  overflow: hidden;
  border-style: solid;
  border-width: 1px;
  border-color: rgb(211, 211, 211);
  background-color: #fff;
  background: rgb(252, 252, 252) url("img/select_garayarrow.png") no-repeat 95%
    50%;

  font-weight: 300;
  height: 47px;
}

.select-addcontact select {
  padding: 8px 30px 10px 12px;
  width: 100%;
  border: none;
  box-shadow: none;
  background-color: transparent;
  background-image: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  color: #373737;
}

.select-addcontact select:focus {
  outline: none;
}

.or_option span {
  background-color: #13a7ff;
  color: #fff;
  font-weight: 400;
  font-size: 20px;
  width: 50px;
  height: 50px;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin: 0 auto;
}

/*--------advance Search--------*/
.advance_search {
  position: relative;
}

.advance_search input {
  border-style: solid;
  border-width: 1px;
  border-color: rgb(211, 211, 211);
  border-radius: 3px;
  background-color: rgb(252, 252, 252);
  height: 47px;
}

.advance_search select {
  width: 100%;
  position: relative;
  left: 0px;
  margin: 0;
  border: 1px solid #dde1e4;
  border-top: none;
  font-size: 14px;
  border-style: solid;
  border-width: 1px;
  border-color: rgb(211, 211, 211);
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 4px 14px 0px rgba(24, 67, 84, 0.22);
}

.advance_search datalist {
  display: none;
}

.advance_search option {
  border-bottom: 1px solid rgb(211, 211, 211);
  padding: 6px 4px;
}

.advance_search option:hover {
  background-color: #eaeaea;
}

.advance_search button {
  width: 50px;
  height: 47px;
  position: relative;
  border-left: none;
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0;
  color: #a1a1a1;
}

/* to hide datalist arrow in webkit */

.advance_search input::-webkit-calendar-picker-indicator {
  display: none;
}

.advance_search .datalist {
  width: 100%;
}

/*-------resoures_sidebar-----*/

.resoures_sidebar {
  background-color: rgb(253, 253, 253);
  box-shadow: 0px 3px 8px 0px rgba(0, 0, 50, 0.15);
}

.resoures_sidebar h4 {
  font-size: 14px;
  color: #184354;
  font-weight: 500;
  position: relative;
}

.resoures_sidebar h4 i {
  border: solid black;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  color: #224a5a;
  position: absolute;

  right: 14px;
  top: 16px;
}

.resoures_sidebar h4 .right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.resoures_sidebar ul {
  margin: 0px;
  padding: 0px;
}

.resoures_sidebar ul li {
  margin: 0px;
  padding: 0px;
}

.resoures_sidebar ul li a {
  padding: 6px 10px 6px 10px;
  color: #184354;
  font-weight: 400;
  font-size: 15px;
  border-left: 3px solid #fff;
  display: block;
  border-top: 1px solid #e8eaee;
}

.resoures_sidebar ul li a .icon {
  margin-right: 5px;
}

.resoures_sidebar ul li.icon a {
  background-position: 7px 13px;
  background-repeat: no-repeat;
  transition: none;
}

.resoures_sidebar ul li.icon_webinars a {
  background-image: url(img/icon_webinars.png);
  background-position: 7px 13px;
  background-repeat: no-repeat;
  transition: none;
}

.resoures_sidebar ul li.icon_videos a {
  background-image: url(img/icon_videos.png);
  background-position: 7px 13px;
  background-repeat: no-repeat;
  transition: none;
}

.resoures_sidebar ul li.icon_blog a {
  background-image: url(img/icon_blog.png);
  background-position: 7px 13px;
  background-repeat: no-repeat;
  transition: none;
}

.resoures_sidebar ul li a:hover,
.resoures_sidebar ul li.current a {
  color: #13a7ff;
  border-left: 3px solid #13a7ff;
  background-color: #e8eaee;
}

.resoures_sidebar ul li.icon_webinars a:hover,
.resoures_sidebar ul li.current a {
  background-position: 7px -41px;
}

.resoures_sidebar ul li.icon_videos a:hover,
.resoures_sidebar ul li.current a {
  background-position: 7px -45px;
}

.resoures_sidebar ul li.icon_blog a:hover,
.resoures_sidebar ul li.current a {
  background-position: 7px -40px;
}

.todaywebi_block {
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 4px 6px 0px rgba(188, 188, 188, 0.27);
}

.webnars_th {
  padding: 11px;
  text-align: center;
}

.todaywebi_block h4 {
  color: #13a7ff !important;
  font-size: 17px;
  font-weight: 500;
}

.todaywebi_block p {
  color: #6e6e6e !important;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
}

.webnars_msg {
  max-height: 114px;
  min-height: 114px;
  overflow: hidden;
  padding: 11px;
}

.resever_btn {
  background-color: #13a7ff;
  padding: 10px;
  text-align: center;
  color: #ffffff;
  font-size: 17px;
  font-weight: 500;
}

.resever_btn:hover {
  background-color: #f2f2f2;
  color: #13a7ff;
}

.enrolled_btn {
  background-color: #f7f7f7;
  padding: 10px;
  text-align: center;
  color: #00d378;
  font-size: 17px;
  font-weight: 500;
}

.enrolled_btn:hover {
  background-color: #00d378;
  color: #fff;
}

.event_dd_tt {
  border-bottom: 1px solid #ababab;
  border-top: 1px solid #ababab;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.event_dd_tt span {
  font-size: 14px;
  color: #224a5a;
  padding: 4px 8px;
  display: block;
  position: relative;
}

.event_dd_tt span:after {
  position: absolute;
  width: 1px;
  height: 23px;
  background-color: #ababab;
  content: "";
  right: -5px;
}

.event_dd_tt span:last-child:after {
  display: none;
}

.webinartitle {
  color: #224a5a;
  font-size: 19px;
  font-weight: 600;
}

.video_th {
  padding: 11px;
  position: relative;
}

.video_th span {
  position: absolute;
  top: 40%;
  left: 0;
  right: 0;
  background-image: url(img/play_icon.png);
  width: 60px;
  height: 60px;
  margin: 0 auto;
}

.video_msg {
  max-height: 90px;
  min-height: 90px;
  overflow: hidden;
  padding: 6px 11px;
}

.video_msg h4 {
  color: #224a5a;
  font-size: 20px;
  font-weight: 500;
}

.video_msg p {
  color: #6e6e6e;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
}

.blogpostby {
  padding: 2px 11px;
  font-size: 14px;
  color: #6e6e6e;
}

.postimg {
  background-color: #f2f2f2;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  float: left;
}

.postmsg {
  width: calc(100% - 35px);
  padding: 4px 0 4px 15px;
  float: left;
  font-size: 14px;
}

.postmsg small {
  color: #224a5a;
  font-size: 14px;
}

.player {
  width: 265px;
  height: 237px;
}

.footer-social li {
  display: inline-block;
}

.footer-social li:after {
  content: "|";
  margin: 0 10px;
}

.footer-social li:last-child:after {
  content: "";
}

.react-datepicker-wrapper {
  display: inline !important;
}

.badge {
  font-weight: 500;
}

.custom-date-button {
  background-color: #999999;
  box-shadow: 0px 4px 14px 0px rgba(19, 167, 255, 0.45);
  color: #fff;
  justify-content: center;
  text-align: center;
  border-radius: 5px !important;
  border: 1px solid #999999;
  padding: 20px;
}
.custom-reminder-button {
  background-color: #999999;
  border: 1px solid #999999;
  color: #fff;
  justify-content: center;
  text-align: center;
  border-radius: 5px !important;
  border: 1px solid #999999;
  padding: 9px 13px;
}

.active-reminder {
  background-color: rgb(19, 167, 255);
  box-shadow: 0px 4px 14px 0px rgba(19, 167, 255, 0.45);
  border: 1px solid rgb(19, 167, 255);
}
.custom-date-button.active {
  background-color: rgb(19, 167, 255);
  border: 1px solid rgb(19, 167, 255);
}
.custom-date-button.missing {
  background-color: rgb(219, 67, 55);
  border: 1px solid rgb(219, 67, 55);
}

.custom-date-button {
  font-size: 14px;
}
.custom-date-button span.month-text {
  top: 60px;
  position: absolute;
  left: 27px;
  font-weight: 900;
  border-bottom: 1px solid;
}

.custom-date-button:hover span.month-text {
  top: 60px;
  position: absolute;
  left: 27px;
  font-weight: 900;
  border-bottom: 1px solid;
}

.custom-date-button span.date-text {
  position: absolute;
  left: 36px;
}

.expenses-block .react-datepicker-popper {
  left: -30px !important;
  top: 20px !important;
  z-index: 100000;
}

.expenses-block .react-datepicker-popper[data-placement^="top"] {
  top: 0px !important;
}
.property_address p .badge {
  color: white;
}
/*
body .flex-column {
  min-height: 100vh;
} */

main .flex-fill {
  flex: 1 1 auto;
}

.attachment-name {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

/* Resources stylings */

.webinars-webinars {
  max-width: 78rem;
  margin: 0 auto;
  /* padding: 3rem 1rem 6rem; */
}

.webinars-webinar {
  background: #fff;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  border-radius: 2px;
  border: 1px solid #c2c6c9;
  display: flex;
  font-size: 1rem;
}
.webinars-webinars .webinars-webinar:last-child {
  margin-bottom: 0;
}

.webinars-webinars .webinars-webinar {
  margin-bottom: 2rem;
}

.webinars-webinar .webinars-webinar-image-container {
  flex: 0 0 30%;
}

.webinars-webinar-image-container {
  position: relative;
  overflow: hidden;
}

.webinars-webinar-image-container .webinars-webinar-image {
  /* width: 100%;
  height: 100%;
  object-fit: cover;
  min-height: 14rem; */
  /* object-fit: cover; */
  /* height: 100%; */
  max-height: 13rem;
  min-height: 13rem;
}

.webinars-webinar-image-container .webinars-webinar-calendar {
  position: absolute;
  top: 1rem;
  left: 1rem;
}

.webinars-webinar-calendar {
  background: #fff;
  padding: 0 1rem;
  -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
  text-align: center;
  font-weight: 300;
}

.webinars-webinar .webinars-webinar-detail {
  flex: 1 1 auto;
}

.webinars-webinar-detail {
  padding: 0 1.5rem;
}

.webinars-webinar-date-time {
  padding: 1rem;
  border-bottom: 1px solid #c2c6c9;
  display: flex;
  align-items: center;
  line-height: 1.333;
}

.webinars-webinar-date-time .webinars-webinar-live,
.webinars-webinar-date-time .webinars-webinar-today,
.webinars-webinar-date-time .webinars-webinar-date,
.webinars-webinar-date-time .webinars-webinar-time {
  margin-right: 1rem;
}

.webinars-webinar-date-time .webinars-webinar-live:last-child,
.webinars-webinar-date-time .webinars-webinar-today:last-child,
.webinars-webinar-date-time .webinars-webinar-date:last-child,
.webinars-webinar-date-time .webinars-webinar-time:last-child {
  margin-right: 0;
}

.webinars-webinar-about {
  padding: 1rem;
}

.webinars-webinar-name {
  /* font-size: 2.375rem;
  display: block;
  margin-bottom: 1.5rem;
  color: #4c4c4c;
  line-height: 1.25; */

  font-size: 1.5rem;
  display: block;
  margin-bottom: 1.5rem;
  line-height: 1.25;
  font-weight: 400;
}
.webinars-webinar-description {
  /* font-size: 2.375rem;
  display: block;
  margin-bottom: 1.5rem;
  color: #4c4c4c;
  line-height: 1.25; */

  font-size: 1rem;
  display: block;
  margin-bottom: 1.5rem;
  line-height: 1.25;
  font-weight: 400;
}

.webinars-webinar-host {
  color: #737373;
  margin-bottom: 1rem;
}

.webinars-webinar-actions {
  display: flex;
  align-items: flex-end;
}

.webinars-webinar-calendar-day {
  font-size: 1.75rem;
  font-weight: 400;
  color: #00aeff;
}

.webinars-webinar-calendar-month {
  font-size: 1.25rem;
}
.webinars-webinar-detail-value {
  color: #262626;
}

.webinars-webinar-actions {
  display: flex;
  align-items: flex-end;
}

.join-webinar-button {
  display: inline-block;
  box-sizing: border-box;
  cursor: pointer;
  padding: 0 24px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
  border: none;
  -webkit-appearance: none;
  font-size: 1rem;
  line-height: 40px;
  height: 40px;
  color: #fff;
  background: #00aeff;
}

.join-webinar-button:hover {
  background-color: #f2f2f2;
  color: #13a7ff;
}

.search_assets input {
  font-family: "Poppins", sans-serif;
  border: none;
}

.search_assets .search-pick {
  background-color: #ffffff;
}

.pagination ul {
  display: inline-block;
  padding-left: 15px;
  padding-right: 15px;
}

.pagination li {
  display: inline-block;
}

.react-tagsinput--focused {
  border-color: #80bdff !important;
}

.react-tagsinput-input::placeholder {
  color: #666;
}

.webinars-webinar-view-date-time {
  padding: 1.5rem;
  padding-left: 0;
  border-bottom: 1px solid #c2c6c9;
  display: flex;
  align-items: center;
  line-height: 1.333;
}

/* Top add button */

.top_add_button a {
  background-color: rgb(19, 167, 255);
  width: 185px;
  height: 41px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  border: 1px solid rgb(19, 167, 255);
  font-weight: 500;
  font-size: 14px;
}

.top_add_button a svg.add {
  width: 14px;
  fill: #fff;
  margin-right: 6px;
}

.top_add_button a:hover {
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 1px 43px 0px rgba(57, 73, 76, 0.2);
  width: 185px;
  height: 41px;
  color: rgb(19, 167, 255);
  border: 1px solid rgb(19, 167, 255);
}

.top_add_button a:hover svg.add {
  fill: rgb(19, 167, 255);
}

.error-message {
  color: red;
}

.date-range-filter {
  background-color: #fff;
}

.date-range-filter .react-datepicker__input-container {
  padding: 12px 0px;
  font-size: 15px;
}

.date-range-filter .date-bettween-text {
  margin: 12px 0px;
  font-weight: 600;
  font-size: 15px;
}

.date-range-filter .react-datepicker__triangle {
  display: none;
}

.date-range-filter .react-datepicker-popper {
  margin-top: 2px !important;
}

.btn {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
}
.form-control,
.form-control-file {
  font-family: "Poppins", sans-serif;
}
input {
  font-family: "Poppins", sans-serif;
}

/* Notification */
.dropdown-menu {
  left: unset;
  right: 0;
  padding: unset;
}

.topbar__collapse-title-wrap {
  border-bottom: 1px solid #eff1f5;
  padding: 20px 15px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.topbar__collapse-title {
  font-size: 14px;
  line-height: 16px;
  padding: 0px 5px;
}

.topbar__collapse-item {
  border-bottom: 1px solid #eff1f5;
  padding-left: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
  display: flex;
  position: relative;
  /* height: 50px; */
  align-items: center;
  flex-wrap: wrap;
}

.topbar__collapse-message {
  margin: 0;
  font-size: 13px;
  line-height: 16px;
  color: #232329;
}

.topbar__collapse-date {
  position: absolute;
  top: 12px;
  font-size: 10px;
  color: #787985;
  margin-top: 2px;
}

.sidebar-image-overlay {
  position: absolute;
  top: 0.3rem;
  right: 1rem;
  background: #13a7ff;
  padding: 0 1rem;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
  text-align: center;
  font-weight: 300;
}

.image-overlay-type {
  font-size: 1.3rem;
  color: #fff;
  text-transform: uppercase;
}

.sidebar-image-overlay.note {
  background: #61697a;
}
.sidebar-image-overlay.primary {
  background: #9553f6;
}

.unpaid-highlight {
  background-color: #f8ff8cd9;
}

.view-reminder-btn {
  background-color: rgb(19, 167, 255);
  border-color: rgb(19, 167, 255);
}

.view-reminder-btn:hover {
  background-color: rgb(255, 255, 255);
  color: rgb(19, 167, 255);
  border: 1px solid rgb(19, 167, 255);
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.hide {
  display: none;
}

.custom-label {
  margin-bottom: 0 !important;
}

.tab-pane form .toggle {
  display: block;
}
.wizard__step.wizard__step--active {
  background: #13a7ff;
  border-color: #13a7ff;
}

.profile__avatar img {
  opacity: 1;
  /* display: block; */
  /* width: 100%; */
  /* height: auto; */
  transition: 0.5s ease;
  backface-visibility: hidden;
}

.profile__avatar:hover img {
  opacity: 0.3;
  cursor: pointer;
}

.profile__avatar:hover .middle {
  opacity: 1;
}

.middle {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
  cursor: pointer;
}

.tab-content {
  padding: 0 !important;
}

.partner .badge-pill {
  font-size: 14px;
  padding: 8px;
  margin: 0px 5px;
}

.partner .badge-pill:first-child {
  margin: 0px;
}

@media (min-width: 992px) {
  .app-inner-content-layout-fixed {
    left: 88px !important;
    right: 88px !important;
    bottom: 88px !important;
    top: 88px !important;
  }

  .app-inner-content-layout--main {
    height: auto !important;
  }
  .app-inner-content-layout--sidebar {
    z-index: 0 !important;
  }
}

.react-bootstrap-table-page-btns-ul {
  float: right;
}

.scroll-area {
  .react-bootstrap-table {
    th {
      position: sticky;
      top: 0;
    }
  }
}

.cursor-pointer {
  cursor: pointer;
}

.datepicker.is-invalid > .invalid-feedback,
.datepicker.is-invalid > .invalid-tooltip {
  display: block;
}

.filed-150 {
  width: 150px;
}

.financial-input {
  .input-group > .form-control:not(:first-child) {
    height: calc(1.5em + 1.68rem + 6px);
  }
}

.rc-switch-custom {
  &[aria-checked="false"] {
    background: #f83245;
    color: #fff;
    border-color: #f83245;
  }
}

.more-info-panel.panel-open > .btn .accordion-icon {
  transform: rotate(180deg);
}
.more-info-panel .btn .accordion-icon {
  transition: all 0.2s ease-in-out;
}

.login-screen {
  a {
    color: #007bff;
    text-decoration: none;
    background-color: transparent; // Remove the gray background on active links in IE 10.
  }
  a:hover {
    color: #0056b3;
    text-decoration: underline;
  }
}

.dash-filter {
  .input-group,
  .entity-select {
    min-width: 150px;
    position: relative;

    .entity-select__value-container {
      height: calc(1.5em + 1.68rem + 2px);
      top: 10px;
    }
    .label-inside {
      position: absolute;
      top: 6px;
      left: 12px;
      font-size: 14px;
      z-index: 100;
    }
  }

  .dropdown-menu {
    z-index: 100 !important;
  }
}

.react-bootstrap-table table {
  table-layout: auto;
}

.slide-pane__overlay {
  z-index: 9999;

  .modal-content {
    border: none;
    border-radius: 0;

    box-shadow: none;
  }

  .modal-header.bg-primary button {
    color: white;
  }
}

.slide-pane__close {
  margin-left: 0px !important;
  svg {
    width: 30px;
  }
}
.slide-pane__title {
  text-align: center;
  font-weight: 500 !important;
}

.slide-pane__content {
  padding: 0px;
  margin: 0;
  display: flex;
  flex-direction: column;

  // .modal-footer {
  //   position: fixed;
  //   bottom: 0;
  //   width: 100%;
  // }
}

.dropzone-upload-wrapper .dropzone-inner-wrapper-sm {
  padding: 0px !important;
}

.app-page-title {
  .breadcrumb {
    background-color: white !important;
  }

  .breadcrumb-item.active {
    color: #3c44b1;
  }
}
