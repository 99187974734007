//Marketplace css
.search-container {
	width: 100%;
	height: 100%;
	padding: 15px 25px;
	background-color: #eeecec;
	border-radius: 5px;

	.search-container-main {
		width: 100%;
		.search-action-sort {
			width: 30%;
			margin-right: 10px;
			background-color: hsl(135, 69%, 79%);
		}
	}
	.search-container-filter {
		width: 25%;
		margin-right: 10px;
		background-color: hsl(135, 79%, 64%);
	}
	.search-container-sort {
		width: 100%;

		.form-select {
			display: block;
			width: 100%;
			padding: 0.575rem 2.25rem 0.375rem 0.75rem;
			font-size: 1rem;
			font-weight: 400;
			line-height: 1.5;
			color: #212529;
			background-color: #fff;
			background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
			background-repeat: no-repeat;
			background-position: right 0.75rem center;
			background-size: 16px 12px;
			border: 1px solid #ced4da;
			border-radius: 0.25rem;
			-webkit-appearance: none;
			appearance: none;
			cursor: pointer;
		}

		.filter-action-sort {
			margin-right: 10px;
			width: 100%;
			white-space: nowrap;

			a {
				text-decoration: none;
				&:hover {
					color: #000000;
				}
			}
		}
	}
}
.opportunity-card-list {
	a {
		text-decoration: none;
		&:hover {
			color: #000;
		}
	}
}
.card {
	.content-card-image {
		height: 230px;
	}

	.date-posted {
		font-size: 8px;
		font-weight: 700;
		color: #ffffff;
		height: 15px;
		padding: 8px;
		border-radius: 2px;
		display: flex;
		justify-content: center;
		align-items: center;
		position: absolute;
		top: 10%;
		left: 3%;
	}

	.heart-icon {
		position: absolute;
		bottom: 5%;
		right: 3%;
		color: #ffffff;
		font-size: 10px;
		transition: 200ms ease-in-out;

		&:hover {
			color: #2f55d4;
		}
	}

	.star-text {
		color: #000000;
		font-size: 11px;
		border: none;
		font-family: Nunito;
		font-weight: bold;
		display: flex;
		justify-content: center;
		align-items: center;
		position: absolute;
		top: 10%;
		left: 35%;
	}
	.button-available {
		display: flex;
		justify-content: center;
		align-items: center;
		position: absolute;
		top: 1%;
		right: 5%;
		width: 65px;
		height: 22px;
		margin: 15.4px 4.4px 0 58.5px;
		padding: 0 12px 0 14.7px;
		border-radius: 21px;
		background-color: #02b76e;
		color: #fff;
		font-size: 11px;
		border: none;
		font-family: Nunito;
		font-weight: bold;
	}

	.button-sold {
		display: flex;
		justify-content: center;
		align-items: center;
		position: absolute;
		top: 1%;
		right: 5%;
		width: 70px;
		height: 22px;
		margin: 15.4px 4.4px 0 58.5px;
		padding: 0 10px 0 10.7px;
		border-radius: 21px;
		background-color: #dd340a;
		color: #fff;
		font-size: 11px;
		border: none;
		font-family: Nunito;
		font-weight: bold;
	}

	.card-content {
		padding: 13px !important;
		margin: 0px !important;

		.sale-details {
			font-size: 12px;
			margin-right: 15px;
			.sale-details-number {
				font-weight: 700;
				margin-left: 7px;
			}

			.sale-icon {
				height: 14px;
				width: 14px;
				color: #ccc9c9;
			}
		}

		.address {
			font-size: 12px;
			color: #000000;
		}

		.card-margin-ruler {
			color: #ccc9c9;
		}

		.price-container {
			height: 30px;

			.price-details {
				font-size: 10px;
				color: #ccc9c9;
			}
		}
		.email-button {
			width: 100%;
			height: 42px;
			margin: 20.4px 23.4px 0 58.5px;
			padding: 0 14.7px 0 14.7px;
			border-radius: 21px;
			background-color: #e1f1fe;
			font-size: 14px;
			display: flex;
			justify-content: center;
			align-items: center;
			margin-top: 10px;
			border: none;
			font-family: Nunito;
			font-weight: bold;
		}
	}
}

img {
	object-fit: cover;
}
.bookmark-card {
	width: 320px;
	.delete-button {
		width: 100px;
		height: 42px;
		margin: 20.4px 23.4px 0 58.5px;
		padding: 0 43px 0 14.7px;
		background-color: #fff;
		color: #8897a9;
		font-size: 14px;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-top: 10px;
		border: none;
		font-family: Nunito;
		margin-left: 80%;
	}
}
.button {
	position: absolute;
	top: 20px;
	left: 70%;
}

//Table CSS

// partner detail css
.details-row {
	display: flex;
	flex-direction: row;
}
.Info {
	h1 {
		font-size: 32px;
		font-family: Nunito;
	}
	p {
		font-size: 16px;
		font-family: Nunito;
		color: #8897a9;
	}
	h3 {
		font-size: 21px;
		font-family: Nunito;
	}
}
.Ratings {
	h3 {
		font-size: 21px;
		font-family: Nunito;
	}
	p {
		font-size: 18px;
		font-family: Nunito;
	}
}
.Ratings {
	background-color: #e1f1fe;
	padding: 25px;
	h3 {
		font-size: 16px;
		font-family: Nunito;
		font-weight: bold;
		margin-top: -5px;
	}
	h4 {
		font-size: 18px;
		font-family: Nunito;
		font-weight: bold;
		margin-top: -5px;
	}
	.chart {
		flex-direction: row;
	}
}

.Testimonials {
	h3 {
		font-size: 21px;
		font-family: Nunito;
	}
	.Knowmore-button {
		width: 170px;
		height: 42px;
		margin: 20.4px 23.4px 0 58.5px;
		padding: 0 12px 0 14.7px;
		border-radius: 21px;
		background-color: #e1f1fe;
		color: #161c2d;
		font-size: 14px;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-top: 30px;
		border: none;
		font-family: Nunito;
		font-weight: bold;
	}
}
.data {
	background-color: #f3f3f3;
	padding: 15px;

	p {
		font-size: 14px;
		font-family: Nunito;
	}
	h6 {
		font-size: 14px;
		font-family: Nunito;
		font-weight: bold;
		margin-top: 12px;
		margin-left: 10px;
	}
}

.provider-image {
	margin-left: 25%;
	margin-top: -10%;
}
.start-button {
	width: 200px;
	height: 42px;
	margin: 20.4px 23.4px 0 58.5px;
	padding: 0 5px 0 14.7px;
	background-color: #2f55d4;
	color: #fff;
	font-size: 14px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 28px;
	border: none;
	font-family: Nunito;
	font-weight: bold;
	border-radius: 25px;
	margin-left: 25%;
}
.owned-card {
	width: 100%;
	height: 100%;
	.card-img {
		height: 200px;
		width: 100%;
	}
	h6 {
		font-size: 14px;
		font-family: Nunito;
		font-weight: bold;
	}
}

//Opportunity detail page css
.opportunity-container {
	.main-title {
		display: flex;
		justify-content: flex-start;
		align-items: center;

		h2 {
			font-weight: 300;
			font-size: 32px;
		}

		.sub-main-title {
			flex-direction: row;
			margin-left: 1.2rem;
			h2 {
				font-weight: 300;
				font-size: 27px;
			}
			.label-available {
				display: flex;
				justify-content: center;
				align-items: center;
				width: 65px;
				height: 22px;
				margin: 0px 0px 0px 18.5px;
				padding: 0 12px 0 14.7px;
				border-radius: 21px;
				background-color: #02b76e;
				color: #fff;
				font-size: 11px;
				border: none;
				font-family: Nunito;
				font-weight: bold;
			}

			.label-sold {
				display: flex;
				justify-content: center;
				align-items: center;
				width: 65px;
				height: 22px;
				margin: 0px 0px 0px 18.5px;
				padding: 0 10px 0 10.7px;
				border-radius: 21px;
				background-color: #dd340a;
				color: #fff;
				font-size: 11px;
				border: none;
				font-family: Nunito;
				font-weight: bold;
			}

			.bookmark-icon {
				color: #000000;
				font-size: 10px;
				transition: 200ms ease-in-out;

				&:hover {
					color: #2f55d4;
				}
			}
		}
	}

	.icons {
		align-items: flex-start;
		.details {
			font-size: 14px;
			margin-right: 20px;
			margin-top: 15px;
			.details-number {
				font-weight: 700;
				margin-left: 8px;
			}
		}
	}

	.main-image-div {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: row;
		.main-image-container {
			width: 100%;
			height: 375px;
			object-fit: cover;
			border-radius: 2.5px;

			.main-image {
				width: 600px;
				height: 100%;
				border-radius: 2.5px;
				@media only screen and (max-width: 1400px) {
					width: 100%;
				}
			}
		}

		.small-image {
			display: flex;
			justify-content: flex-start;
			align-items: center;
			flex-direction: column;

			.sub-images {
				height: 80px;
				width: 130px;
				border-radius: 7px;
			}

			.sub-image-margin {
				margin-top: 1.2rem;
			}
		}
		.image {
			margin-left: 20px;
		}
	}

	.owner {
		width: 290px;
		height: 344px;
		align-items: center;
	}
	.card {
		.content-card-image-01 {
			height: 80px;
			width: 80px;
			object-fit: contain;
		}

		h6 {
			font-size: 15px;
			font-weight: bold;
			font-family: Nunito;
		}
		p {
			font-size: 10px;
			font-family: Nunito;
		}
		.size {
			font-size: 12px;
			font-family: Nunito;
			text-align: center;
		}
		.message-button {
			width: 170px;
			height: 42px;
			margin: 20.4px 23.4px 0 58.5px;
			padding: 0 43px 0 14.7px;
			border-radius: 21px;
			background-color: hsl(226, 66%, 51%);
			color: #fff;
			font-size: 14px;
			display: flex;
			justify-content: center;
			align-items: center;
			margin-top: 10px;
			border: none;
			font-family: Nunito;
			font-weight: bold;
		}
	}
}
.text-01 {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	h4 {
		font-size: 28px;
		font-weight: 300;
		color: #161c2d;
	}
	.rating-star {
		position: relative;
		margin-left: 10px;
		.rating-text {
			color: #000000;
			font-size: 11px;
			border: none;
			font-family: Nunito;
			font-weight: bold;
			display: flex;
			justify-content: center;
			align-items: center;
			position: absolute;
			top: 30%;
			left: 28.2%;
		}
	}
}
.highlights-container {
	height: 79px;
	background-color: #f9fafb;
	width: 100%;
	padding: 3rem 3rem;

	.highlights-details {
		font-size: 12px;
		color: #8897a9;
	}
}

.tags {
	width: 100%;
	gap: 4rem;
	.tags-01 {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		gap: 4rem;
	}

	.tags-02 {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		width: 100%;
	}

	.highlight-tag {
		width: 100%;
		.bold-text {
			font-weight: 700;
		}
	}
}
.paragraph {
	p {
		font-size: 16px;
		color: #8897a9;
	}
}
.cashflow-main {
	.bold-text {
		font-size: 25px;
		color: #161c2d;
		font-weight: bold;
	}

	.progress-box {
		width: 100%;
		h6 {
			font-size: 14px;
			color: #161c2d;
			font-weight: bold;
		}
		.progress-title {
			width: 100%;
		}
		.progress-input {
			width: 100%;
		}
		.text-dark {
			color: #161c2d;
		}
	}
}

.price-insights {
	width: 100%;
	.price-highlights-container {
		height: 79px;
		width: 100%;
		background-color: #f9fafb;
		padding: 3rem 3rem;
	}

	.estimated-value {
		.highlights-details {
			font-size: 12px;
			color: #8897a9;
		}
		h6 {
			font-size: 16px;
			color: #161c2d;
		}
	}

	.price-insights-01 {
		.price-tags {
			width: 100%;
			.price-tags-01 {
				display: flex;
				justify-content: space-between;
				align-items: center;
				width: 100%;
				white-space: nowrap;
				.price {
					width: 100%;
				}
				.highlights-details {
					font-size: 12px;
					color: #8897a9;
				}
				h6 {
					font-size: 16px;
					color: #161c2d;
				}
			}
		}
	}

	.price-insights-02 {
		.highlights-details {
			font-size: 12px;
			color: #8897a9;
		}
		h6 {
			font-size: 16px;
			color: #161c2d;
		}
	}
}
.tags-02 {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
}
.review-text {
	color: #8897a9;
	font-size: 16px;
}
.home-owner {
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-direction: column;
}

/* Pagination */

.ais-Pagination {
	color: #21243d;
	font-size: 0.75rem;
}

.ais-Pagination--noRefinement {
	display: none;
}

.ais-Pagination-list {
	display: flex;
	justify-content: end;
}

.ais-Pagination-item,
.ais-Pagination-link {
	align-items: center;
	display: flex;
	justify-content: center;
}

.ais-Pagination-item {
	height: 38px;
	width: 38px;
}

.ais-Pagination-item {
	background-color: rgba(65, 66, 71, 0.08);
	border-radius: 4px;
	color: #414247;
}

.ais-Pagination-item--selected {
	background-color: #3c44b1;
	font-weight: bold;
}

.ais-Pagination-item--firstPage,
.ais-Pagination-item--previousPage,
.ais-Pagination-item--nextPage,
.ais-Pagination-item--lastPage {
	background: none;
}

.ais-Pagination-item--disabled {
	opacity: 0.33;
}

.ais-Pagination-item--selected a {
	color: #fff;
}

.ais-Pagination-item.ais-Pagination-item--page {
	margin-right: 4px;
}

.ais-Pagination-item.ais-Pagination-item--previousPage {
	margin-right: 1rem;
}

.ais-Pagination-item.ais-Pagination-item--nextPage {
	margin-left: calc(1rem - 4px);
}

.ais-Pagination-link {
	height: 100%;
	width: 100%;
}

//chat css
.chatcard {
	width: 600px;
	height: 631px;
}
.send-image {
	width: 38px;
	height: 38px;
}
button {
	border: none;
	background: none;
}
.message-form {
	display: flex;
	flex-direction: row;
	border: 1px solid rgb(172, 170, 170);
	padding: 2px;
	border-radius: 25px;
}
.message-input {
	flex-grow: 2;
	border: none;
	border-radius: 15px;
}
.message-input:focus {
	outline: none;
}

.pricing-plan{
	display: flex;
	justify-content: center;
	align-items: flex-start;
	.pricing-plan-details{
		.pricing-plan-description {
			margin-top: 1.5rem;
			font-size: 1.15rem;
			ul {
				display: flex;
				justify-content: flex-start;
				align-items: flex-start;
				flex-direction: column;
			}
		}
		@media screen and (max-width: 960px) {
			flex-direction: column;
		}
	}

	@media screen and (max-width: 960px) {
		flex-direction: column;
	}
}

.payment-details{
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	width: 65%;
	margin: 0 auto;

	h1 {
		color: #2f55d4;
		font-size: 4rem;
	}

	.add-card{
		-webkit-appearance: none;
		-webkit-rtl-ordering: right;
		width: 206px;
		height: 100px;
		padding: 5px;
		color: #8897a9;
		outline: 1px dotted #24abdf;
		border-radius: 4px;
		display: flex;
		justify-content: center;
		align-items: center;
		@media screen and (max-width: 960px) {
			margin-top: 1.5rem;
		}
	}

	@media screen and (max-width: 960px) {
		flex-direction: column;
	}
}

@media screen and (max-width: 960px) {
	.app-page-title--first {
		display: none;
	}
	.header-user {
		display: none;
	}
	.search-container {
		width: 100%;
		height: 100%;

		.search-container-main {
			width: 100%;
		}

		.search-container-sort {
			width: 100%;
			flex-direction: column;
			margin-top: 2rem;
			.filter-action-sort {
				margin-right: 0px;
			}
			.sort-left {
				margin-right: 10px;
			}
			.sort-bottom {
				margin-top: 1.2rem;
			}
		}
	}
	.composedChart {
		width: 800px;
		height: 54px;
	}
	.column-reverse {
		flex-direction: column-reverse;
	}

	// partner page css
	.details-row {
		display: flex;
		flex-direction: column-reverse;
	}
	.Ratings {
		padding: 15px;
		.chart {
			flex-direction: column;
		}
	}
	.Info {
		h1 {
			font-size: 22px;
			font-family: Nunito;
		}
	}
	.provider-image {
		width: 100%;
		margin-left: 0px;
		margin-top: 0px;
	}

	// details page css
	.start-button {
		margin-left: 0px;
	}

	.highlights-container {
		padding: 3rem 1.7rem;
	}
	.opportunity-container {
		.main-title {
			align-items: flex-start;
			flex-direction: column;
			.sub-main-title {
				margin-left: 0.2rem;
			}
		}
		.main-image-div {
			flex-direction: column;
			width: 100%;
			.main-image-container {
				height: 100%;
				.main-image {
					width: 100%;
					height: 100%;
					margin: 20px auto;
				}
			}

			.small-image {
				flex-direction: row;

				.sub-images {
					height: 49.14px;
					width: 70.27px;
					border-radius: 7px;
					margin-right: 10px;
				}

				.sub-image-margin {
					margin-top: 0rem;
				}
			}
			.image {
				width: 100%;
				margin-left: 30px;
			}
		}
	}
	.owned-card {
		margin-top: 10px;
	}
	.owner {
		width: 100%;
		height: 304px;
	}
	.main-image {
		width: 345px;
		height: 191px;
	}

	.tags {
		flex-direction: column;
		height: 270px;
		gap: 2rem;
		.tags-01 {
			gap: 2rem;
			.highlight-tag {
				.bold-text {
					font-weight: normal;
				}
				.highlights-details {
					font-size: 10px;
				}
				h5 {
					font-size: 12px;
				}
			}
		}
	}
	.cashflow-main {
		.bold-text {
			font-size: 20px;
		}
		.cashflow {
			align-items: center;
			.cashflow-1 {
				font-size: 15px;
				font-weight: bold;
				.highlights-details {
					font-size: 10px;
				}
			}
		}
		.progress-box {
			width: 100%;
		}
	}

	.price-insights {
		.price-highlights-container {
			padding: 3rem 1.7rem;
		}
		.price-insights-01 {
			.price-tags {
				flex-direction: column;
				height: 302px;
				.price-tags-01 {
					padding: 0px;
					width: 100%;
					align-items: flex-start;
					white-space: normal;

					.price {
						.long-text {
							width: 50px;
						}
					}
				}
			}
		}
		.price-insights-02 {
			background-color: #f9fafb;
		}
	}
}

//Form css

.h2-title {
	font-size: 32px;
	font-family: Nunito;
}
.custom-select {
	color: #8897a9;
}
::placeholder {
	color: #8897a9 !important;
}
.description {
	color: #8897a9;
	padding: 10px;
}
textarea {
	border-color: #8897a9 !important;
	border-radius: 5px;
	color: #8897a9;
}
.label-title {
	font-size: 16px;
	font-family: Poppins;
	color: #161c2d;
}
input[type="file"] {
	-webkit-appearance: none;
	text-align: right;
	-webkit-rtl-ordering: right;
	width: 356px;
	height: 52px;
	padding: 5px;
	color: #8897a9;
	outline: 1px dotted #24abdf;
	border-radius: 4px;
}
input[type="file"]::-webkit-file-upload-button {
	-webkit-appearance: none;
	float: right;
	border: 1px solid #aaaaaa;
	border-radius: 4px;
	background-color: #202942;
	color: #fff;
	width: 176px;
	height: 44px;
	cursor: pointer;
}
.file-size {
	font-size: 13px;
	font-family: Poppins;
	color: #161c2d;
}
.card-img {
	width: 325px;
	height: 182px;
}
.owner {
	width: 100%;
	height: 327px;
}
