//  Core

.section-header {
  padding: 1em 0;
  margin-bottom: 2em;
  border-bottom: 1px solid #d3d3d3;

  @include media-breakpoint-down(sm) {
    margin: (-($layout-spacer / 3)) (-($layout-spacer / 3)) ($layout-spacer / 3);
    padding: $layout-spacer / 3;
  }

  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: $transition-base;

  @include media-breakpoint-down(md) {
    flex-direction: column;
  }
  &--dark {
    color: rgba($white, 1);
  }

  &--heading {
    color: #184354;
    font-size: 24px;
    font-weight: 400;
    position: relative;
    padding-top: 20px;

    @include media-breakpoint-down(md) {
      padding-right: 0;
      text-align: center;
    }

    h1 {
      font-size: $display4-size / 1.1;
      font-weight: 700;
      margin: 0;
    }
  }

  &--heading:after {
    width: 100%;
    height: 2px;
    background-color: rgb(19, 167, 255);
    content: "";
    bottom: -1px;
    left: 0;
    position: absolute;
  }

  &--iconbox {
    background: $white;
    @include border-radius($border-radius-sm);
    box-shadow: $box-shadow-sm-light;
    display: flex;
    margin: 0 ($spacer * 1.5) 0 0;
    justify-content: center;
    align-items: center;

    @include media-breakpoint-down(md) {
      margin: 0 0 ($spacer * 1.5);
    }

    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

  &--description {
    margin: ($spacer / 2) 0 0;
    font-size: $font-size-base * 1.1;
    opacity: 0.6;
    font-weight: normal;
  }

  // Alternate style 1

  &.section-header-alt-1 {
    background: $white;
    padding-top: ($layout-spacer / 3);
    padding-bottom: ($layout-spacer / 3);

    .section-header--heading {
      display: flex;
      align-items: center;

      h1 {
        font-size: $font-size-xl;
        white-space: nowrap;
      }
    }

    .section-header--description {
      width: 75%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin: 0 0 0 ($spacer / 2);
      font-size: $font-size-base;
    }

    .section-header--iconbox {
      display: none !important;
    }
  }

  // Alternate style 2

  &.section-header-alt-2 {
    margin: (-$layout-spacer) 0 $layout-spacer;
    padding: $layout-spacer;
    @include border-bottom-radius($border-radius);
  }

  // Alternate style 3

  &.section-header-alt-3 {
    margin: 0 0 $layout-spacer;
    padding: ($layout-spacer / 1.5) $layout-spacer;
    @include border-radius($border-radius);

    .section-header--iconbox {
      @include border-radius(100%);
    }
  }
}
